import React from 'react';
import { Link } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ContinueIcon from '@material-ui/icons/ChevronRight';
import EmailSentIcon from '@material-ui/icons/Drafts';

export default function SignupComplete({ first, email }) {
  return (
    <>
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <Avatar>
            <EmailSentIcon />
          </Avatar>
        </Grid>
        <Grid item>
          <Typography variant="h6">Check your email, {first}!</Typography>
        </Grid>
      </Grid>

      <Box mt={3}>
        <Typography paragraph>
          To make sure your email address is valid, we sent an email to{' '}
          <a href={`mailto:${email}`}>{email}</a>.
        </Typography>

        <Typography paragraph>
          You won&apos;t be able to log in or use your new My Digital Hand
          account until you follow the link in the body of the confirmation
          email.
        </Typography>

        <Typography paragraph>
          If you can&apos;t find the email in your inbox,{' '}
          <Link to={`/emails/lookup?email=${email}`}>try resending it</Link>.
          Don&apos;t forget to check your spam folder!
        </Typography>
      </Box>

      <Box mt={2} textAlign="right">
        <Button
          color="primary"
          variant="contained"
          component={Link}
          to="/signin"
          endIcon={<ContinueIcon />}
        >
          Continue to login
        </Button>
      </Box>
    </>
  );
}
