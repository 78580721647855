import React, { useState, useCallback } from 'react';

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

import useCourse from 'selectors/courses/use-course';
import PreInteractionDialog from 'components/dialogs/quizzes/pre-interaction-dialog';

import HelpDialogButton from './help-dialog';
import useIsStaffOnDuty from './use-is-staff-on-duty';
import useTicketsAwaitingFeedback from './use-tickets-awaiting-feedback';
import FeedbackButton from './feedback-link';

export default function HandCardDown({ courseId }) {
  const course = useCourse(courseId);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const isStaffOnDuty = useIsStaffOnDuty(courseId);
  const ticketsAwaitingFeedback = useTicketsAwaitingFeedback(courseId);

  const openDialog = useCallback(() => {
    setIsDialogOpen(true);
  }, []);

  const closeDialog = useCallback(() => {
    setIsDialogOpen(false);
  }, []);

  if (!course) {
    return null;
  }

  const isCourseArchived = course.status === 'archived';
  const isStaffRequired = ['required', 'waitlist'].includes(course.shiftPolicy);
  const isFeedbackRequired = course.isStudentFeedbackRequired;
  const isFeedbackMissing = ticketsAwaitingFeedback.length > 0;

  const isButtonDisabled =
    (isStaffRequired && !isStaffOnDuty) ||
    (isFeedbackMissing && isFeedbackRequired) ||
    isCourseArchived;

  return (
    <>
      <Card>
        <CardContent>
          <Typography paragraph variant="h5">
            Your hand is down
          </Typography>

          <HelpText
            isArchived={course.status === 'archived'}
            isMissingStaff={isStaffRequired && !isStaffOnDuty}
            isMissingFeedback={isFeedbackMissing && isFeedbackRequired}
          />
        </CardContent>

        <CardActions>
          {isFeedbackMissing && isFeedbackRequired ? (
            <FeedbackButton ticket={ticketsAwaitingFeedback[0]}>
              Leave feedback
            </FeedbackButton>
          ) : (
            <Button
              color="primary"
              variant="contained"
              onClick={openDialog}
              disabled={isButtonDisabled}
            >
              Get help now
            </Button>
          )}

          <HelpDialogButton />
        </CardActions>
      </Card>

      <PreInteractionDialog
        courseId={courseId}
        isOpen={isDialogOpen}
        onClose={closeDialog}
      />
    </>
  );
}

function HelpText({ isArchived, isMissingFeedback, isMissingStaff }) {
  if (isArchived) {
    return (
      <Typography variant="body1" color="error">
        This course has been archived by your instructor, and you can no longer
        raise your hand.
      </Typography>
    );
  } else if (isMissingFeedback) {
    return (
      <Typography variant="body1" color="error">
        To get help, you must first provide feedback about your most recent
        experience at office hours.
      </Typography>
    );
  } else if (isMissingStaff) {
    return (
      <Typography variant="body1" color="error">
        There are currently no staff members on duty. Once someone is holding
        office hours, you&apos;ll be able to add yourself to the waitlist.
      </Typography>
    );
  } else {
    return (
      <Typography variant="body1" color="textSecondary">
        Raise your hand to be added to the office hours waitlist. If other
        students arrived before you, they&apos;ll be helped first.
      </Typography>
    );
  }
}
